import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/es6-let-const",
  "date": "2015-09-04",
  "title": "ES6 LET & CONST",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "If you are familiar with JavaScript, you have probably known the term scope. If you are familiar with other languages, you would know that a scope of a variable is to the nearest block – block scope. But JavaScript is different. A variable created with var will be scoped to the nearest parent function."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`let`}</h2>
    <p>{`We can imagine that `}<inlineCode parentName="p">{`let`}</inlineCode>{` is a new `}<inlineCode parentName="p">{`var`}</inlineCode>{` statement. But there is no need to replace every `}<inlineCode parentName="p">{`var`}</inlineCode>{` with `}<inlineCode parentName="p">{`let`}</inlineCode>{`, but only when you require a block scoped variables.`}</p>
    <p>{`Firstly, imagine and old way (and still valid) to declare a new variable in your JS code using ES5:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var foo = 1;

console.log(foo); // 1

{
  var bar = 2;
  console.log(bar); // 2
}

function fn() {
  console.log(foo); // undefined
  var foo = 3;
  console.log(foo); // 3
}

fn();

console.log(foo); // 1
console.log(bar); // 2
`}</code></pre>
    <p>{`Now lets write the same code using `}<inlineCode parentName="p">{`let`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let foo = 1;

console.log(foo); // 1

{
  let bar = 2;
  console.log(bar); // 2
}

function fn() {
  console.log(foo); // undefined
  var foo = 3;
  console.log(foo); // 3
}

fn();

console.log(foo); // 1
console.log(bar); // ReferenceError: bar is not defined
`}</code></pre>
    <p>{`As we can see, this time only variable `}<strong parentName="p">{`foo`}</strong>{` is declared as a `}<inlineCode parentName="p">{`global`}</inlineCode>{`. `}<inlineCode parentName="p">{`let`}</inlineCode>{` gives us a way to declare block scoped variables, which is `}<inlineCode parentName="p">{`undefined`}</inlineCode>{` outside it.`}</p>
    <Message type="info" title="REPL" content="You can use `Babel` or `Traceur` to transpile ES6 code." mdxType="Message" />
    <h2>{`const`}</h2>
    <p><inlineCode parentName="p">{`const`}</inlineCode>{` is single-assignment and like a `}<inlineCode parentName="p">{`let`}</inlineCode>{`, block-scoped declaration.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
 const PI = 3.141593;
 PI = 3.14; // throws "PI" is read-only
}

console.log(PI); // throws ReferenceError: PI is not defined
`}</code></pre>
    <p><inlineCode parentName="p">{`const`}</inlineCode>{` cannot be reinitialized. It will throw an Error when we try to assign another value.`}</p>
    <p>{`Let’s look for the equivalent in ES5:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var PI = (function () {
  var PI = 3.141593;
  return function () { return PI; };
})();
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      